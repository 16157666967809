import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { ManualLoader } from "src/components";
import { useMediaQuery } from "@mui/material";

/**
 * If authorized, return an outlet that will render child elements
 * If not, return element that will navigate to login page
 */
const PrivateRoute = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const matches = useMediaQuery("(min-width: 768px)");
  if (isAuthenticated && !matches) {
    // @ts-ignore
    window["ZohoHCAsap"].Action("hideLauncher");
  }
  if (matches) {
    // @ts-ignore
    window["ZohoHCAsap"].Action("showLauncher");
  }

  return (
    <>
      {isLoading && <ManualLoader />}
      {isAuthenticated && !isLoading && <Outlet />}
      {!isAuthenticated && !isLoading && <Navigate to="/" />}
    </>
  );
};

export default PrivateRoute;
