import { useTranslation } from "react-i18next";

export const getSettingsData = (
  t: any,
  isNullUserObject: boolean,
  rentReporting: {
    isCreditReportingReady: boolean;
    isDisabledTheCreditReportingFeature: boolean;
  }
) => {
  const settingObj = [
    {
      title: t("settings.AccountInformation.title"),
      id: "AccountInformation",
      icon: "PersonIcon",
      buttonDetails: {
        buttonEnable: true,
        buttonText: "Resident",
        color: "gray-blue",
        type: "gray-blue",
      },
      isHidden: isNullUserObject,
    },
    {
      title: t("settings.RentReporting.title"),
      id: "RentReporting",
      icon: "RentReportingIcon",
      buttonDetails: {
        buttonEnable: rentReporting.isCreditReportingReady,
        buttonText: rentReporting.isCreditReportingReady
          ? "Enabled"
          : "Disabled",
        color: rentReporting.isCreditReportingReady ? "gray-blue" : "red.500",
        type: rentReporting.isCreditReportingReady ? "gray-blue" : "red.500",
      },
      isHidden:
        isNullUserObject || rentReporting.isDisabledTheCreditReportingFeature,
    },
    {
      title: t("settings.Languages.title"),
      id: "Languages",
      icon: "WorldIcon",
      buttonDetails: {
        buttonEnable: true,
        buttonText: t("settings.Languages.buttonText"),
        color: "gray-blue",
        type: "gray-blue",
      },
      isHidden: false,
    },
    {
      title: "Knowledge Base",
      id: "Help",
      icon: "HelpIcon",
      buttonDetails: { buttonEnable: false, buttonText: "", color: "" },
      isHidden: false,
    },
    {
      title: "Ask a Question",
      id: "Question",
      icon: "QuestionIcon",
      buttonDetails: { buttonEnable: false, buttonText: "", color: "" },
      isHidden: false,
    },
    {
      title: t("settings.LogOut.title"),
      id: "LogOut",
      icon: "LogoutIcon",
      buttonDetails: { buttonEnable: false, buttonText: "", color: "" },
      isHidden: false,
    },
  ];

  return settingObj;
};

export const getVerificationDetails = (status: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  switch (status) {
    case "verified":
      return {
        color: "green.500",
        text: t("settings.AccountInformation.buttonTextVerified"),
      };
    case "restricted":
      return {
        color: "red.500",
        text: t("settings.AccountInformation.buttonTextRestricted"),
      };
    case "pending":
      return {
        color: "darkBlue.300",
        text: t("settings.AccountInformation.buttonTextPending"),
      };
    default:
      return {
        color: "red.500",
        text: t("settings.AccountInformation.buttonTextUnverified"),
      };
      // eslint-disable-next-line no-unreachable
      break;
  }
};

export const getFirstLastName = (fullName?: string) => {
  if (!fullName) return { firstName: "", lastName: "" };

  const names = fullName.trim().split(/\s+/);
  const firstName = names[0];
  let lastName = "";
  if (names.length > 1) {
    lastName = names[names.length - 1];

    // Handle middle names if present
    if (names.length > 2) {
      // Concatenate middle names
      for (let i = 1; i < names.length - 1; i++) {
        lastName += " " + names[i];
      }
    }
  }
  return { firstName, lastName };
};

export const getFullName = (firstName?: string, lastName?: string) => {
  let fullName = "";
  if (firstName) fullName = firstName + " ";
  if (lastName) fullName += lastName;
  return fullName;
};
