import { Box, Button, Typography } from "@mui/material";
import {
  AfterBottomRow,
  ButtonAuthorizedWrapper,
  Messages,
  Modal,
  ShadowCard,
} from "src/components";
import { handleAmount } from "src/helpers/helperRent";
import { PaymentSummaryModalProps } from "src/shared/models/containers/rent.model";
import { theme } from "src/shared/theme/theme";

export const PaymentSummaryModal = ({
  summaryDetails,
  t,
  onPayamentSummaryBtnClickEvent,
  onPayamentSummaryModalCloseEvent,
  setErrorMessage,
  errorMessage,
}: PaymentSummaryModalProps) => {
  const getTotalAmount =
    parseFloat(summaryDetails.fee.toFixed(2)) +
    parseFloat(summaryDetails.amount.toFixed(2));

  return (
    <Modal onCloseEvent={onPayamentSummaryModalCloseEvent} isMobileBottomFixed>
      <Typography
        component="p"
        variant="h2Bold"
        textTransform={"uppercase"}
        sx={{
          marginBottom: 4,
          display: "inline-flex",
          alignItems: "center",
          color: "gray.500",
        }}
      >
        {"Payment Summary"}
      </Typography>
      <ShadowCard padding={4} marginBottom={6}>
        <Box>
          <AfterBottomRow className="content" justifyContent={"space-between"}>
            <Box width={150}>
              <Typography variant="h2Bold" component={"p"} color={"gray.900"}>
                {"Payment method"}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h2" component={"p"} color={"gray.900"}>
                {summaryDetails.paymentMethodType === ""
                  ? "----"
                  : summaryDetails.paymentMethodType}
              </Typography>
            </Box>
          </AfterBottomRow>
          <AfterBottomRow className="content" justifyContent={"space-between"}>
            <Box width={150}>
              <Typography variant="h2Bold" component={"p"} color={"gray.900"}>
                {`Fee ${
                  summaryDetails.feepercentage !== ""
                    ? `(${summaryDetails.feepercentage})`
                    : ""
                }`}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h2" component={"p"} color={"gray.900"}>
                {handleAmount(summaryDetails.fee)}
              </Typography>
            </Box>
          </AfterBottomRow>
          <AfterBottomRow className="content" justifyContent={"space-between"}>
            <Box width={150}>
              <Typography variant="h2Bold" component={"p"} color={"gray.900"}>
                {"Amount"}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h2" component={"p"} color={"gray.900"}>
                {handleAmount(summaryDetails.amount)}
              </Typography>
            </Box>
          </AfterBottomRow>
          <AfterBottomRow className="content" justifyContent={"space-between"}>
            <Box width={150}>
              <Typography
                variant="h2ExtraBold"
                component={"p"}
                color={"gray.900"}
              >
                {"Total Amount"}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h2ExtraBold"
                component={"p"}
                color={"gray.900"}
              >
                {handleAmount(getTotalAmount)}
              </Typography>
            </Box>
          </AfterBottomRow>
        </Box>
      </ShadowCard>
      {errorMessage !== "" && (
        <Messages
          topMargin={0}
          messageHeading={"Make Payment"}
          messageContent={errorMessage}
          closeEvent={() => setErrorMessage("")}
        />
      )}
      <ButtonAuthorizedWrapper
        sx={{
          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            paddingBottom: 3,
            paddingX: 4,
          },
        }}
      >
        <Button
          variant="containedLarge"
          color="primary"
          fullWidth
          type="button"
          sx={{ overflow: "hidden" }}
          onClick={onPayamentSummaryBtnClickEvent}
        >
          {t("buttonTexts.continueButton")}
        </Button>
      </ButtonAuthorizedWrapper>
    </Modal>
  );
};
