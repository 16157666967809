import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/system";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  Header,
  HeaderFixed,
  Wrapper,
  WrpperAuthorizedContainer,
  UserIdentitify,
  InformationList,
  Messages,
} from "src/components";
import { getSettingsData } from "src/helpers/helperSettings";
import { useGetUserQuery } from "src/store/services/privateApi";

export const SettingsScreenContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const { tpRentReportingFeature } = useFlags();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const { data, isSuccess, isLoading } = useGetUserQuery(null);

  // check is credit reporting is enabled or not, using creditConsumerId ___
  const isCreditReportingReady =
    isSuccess && data && data?.user?.isCreditReportingReady;

  const clickAction = (type: any) => {
    switch (type) {
      case "LogOut":
        logout({ logoutParams: { returnTo: window.location.origin } });
        break;
      case "AccountInformation":
        navigate("/settings/account-info");
        break;
      case "RentReporting":
        navigate("/rent/reporting/home");
        break;
      case "Languages":
        navigate("/settings/languages");
        break;
      case "Help":
        // @ts-ignore
        window["ZohoHCAsap"].Action("open");
        break;
      case "Question":
        navigate("/settings/question");
        break;
    }
  };

  let settingsObj = getSettingsData(t, isSuccess && data && !data.user, {
    isCreditReportingReady,
    isDisabledTheCreditReportingFeature: !tpRentReportingFeature,
  });

  return (
    <>
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonHide
          paddingX={0}
          isLogoVisible={false}
          headerText={t("settings.HeaderTitle")}
          withCenteredHeaderText
        />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper>
        <WrpperAuthorizedContainer>
          <UserIdentitify
            isLoading={isLoading}
            isSuccess={isSuccess}
            data={data}
          />
          <Box>
            <InformationList onClick={clickAction} data={settingsObj} />
          </Box>
          {errorMessage !== "" && (
            <Messages
              messageHeading={"Identity Verification"}
              messageContent={errorMessage}
              closeEvent={() => setErrorMessage("")}
              bottomMargin={4}
              topMargin={4}
            />
          )}
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
